@font-face {
  font-family: 'SB Text';
  src: url('../../assets/fonts/woff/SBSansTextBold.woff'), url('../../assets/fonts/woff/SBSansTextBold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'SB Text';
  src: url('../../assets/fonts/woff/SBSansTextSemibold.woff'), url('../../assets/fonts/woff/SBSansTextSemibold.woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'SB Text';
  src: url('../../assets/fonts/woff/SBSansTextMedium.woff'), url('../../assets/fonts/woff/SBSansTextMedium.woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'SB Text';
  src: url('../../assets/fonts/woff/SBSansTextRegular.woff'), url('../../assets/fonts/woff/SBSansTextRegular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}
